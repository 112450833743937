export default {
  reservations: [],
  current: [],
  show: false,
  error: false,
  loading: false,
  modal: false,
  info: null,
  user: null,
};
