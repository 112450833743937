import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWifi, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Information = () => {
  return (
    <Jumbotron style={{width:'100%'}}>
      <h4 id={"information"}>Informationen über die Unterkunft</h4>
      <Row>
        <Col lg={6} md={6} xs={12} sm={12} className="mt-4 mb-4">
          <Card className="infoBox">
            <Card.Body>
              <Card.Title className="text-center">
                <FontAwesomeIcon icon={faWifi} size="lg" />
              </Card.Title>
              <Card.Subtitle className="mb-2 text-center">
                Das Wlan Passwort
              </Card.Subtitle>
              <Card.Text className="text-muted text-center">
              Die Gästehaus verfügt über ein Internetzugang.
 <br />
                Hierfür kannst du folgende Anmeldedaten nutzen:
              </Card.Text>
              <Card.Subtitle className="mb-2 text-center">
              Benutzername: Walter36<br />
Passwort: Walter36
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} md={6} xs={12} sm={12} className="mt-4 mb-4">
          <Card className="infoBox">
            <Card.Body>
              <Card.Title className="text-center">
                <FontAwesomeIcon icon={faInfoCircle} size="lg" />
              </Card.Title>
              <Card.Subtitle className="mb-2 text-center">
                Info
              </Card.Subtitle>
              <Card.Text className="text-muted text-center">
              Unsere neue Putzfrau heisst Nubia Pedley, sie ist jeweils Mittwochs zwischen 8.00h und 15:30h vor Ort. 
              Annulationen müssen jeweils 14 Tage vorher mitgeteilt werden, <br />
              ihre Telefonnummer lautet wie folgt:
              </Card.Text>
              <Card.Subtitle className="mb-2 text-center">
             <strong> 079 649 07 68</strong>
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Jumbotron>
  );
};
export default Information;
