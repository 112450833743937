import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers/allReducers";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import localforage from "localforage";

const persistConfig = {
  key: "root",
  storage: localforage,
  stateReconciler: hardSet,
};
const enhancers = [];
const middleware = [thunk];

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  applyMiddleware(logger),
  ...enhancers
);

export default () => {
  const store = createStore(
    persistReducer(persistConfig, rootReducer),
    composedEnhancers
  );

  let persistor = persistStore(store);
  return { store, persistor };
};
