import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { useState } from "react";
const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const doLogin = (e) => {
    e.preventDefault();
    if (username.length >= 3 && password.length >= 3) {
      props.appActions.doLogin(username, password);
    }
  };
  return (
    <Form onSubmit={(e) => doLogin(e)}>
      <h4>Anmelden</h4>
      <p>Bitte melden Sie sich mit Ihrem Benutzernamen und Passwort an, um die Buchungen verwalten zu können</p>
      <Form.Row>
        <Col xs={12} lg={6}>
        <Form.Group  controlId="formGridEmail">
          {/*<Form.Label>Benutzername</Form.Label>*/}
          <Form.Control
            type="text"
            value={username}
            onChange={(e) => setUsername(e.currentTarget.value)}
            required
            placeholder="Benutzername"
          />
        </Form.Group>
</Col> <Col xs={12} lg={3}>
        <Form.Group  controlId="formGridPassword">
          {/*<Form.Label>Passwort</Form.Label>*/}
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            required
            placeholder="Passwort"
          />
        </Form.Group>
        </Col> <Col xs={12} lg={3}>
        <Form.Group  controlId="formGridButton">
          <Button
            variant="primary"
            block
            onClick={(e) => {
              doLogin(e);
            }}
            disabled={username.length < 3 || password.length < 3}
            type="submit"
          >
            Anmelden
          </Button>
        </Form.Group></Col> 
      </Form.Row>
    </Form>
  );
};
export default Login;

//Button style if label style={{ marginTop: "2rem" }}
