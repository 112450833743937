import initialState from "./initialState";
import {
  FETCH_DATA,
  RECEIVE_DATA,
  IS_ERROR,
  IS_LOADING,
  SEARCH,
  FETCH_INFO,
  SHOW_MODAL,
  SET_USER,
  LOGOFF,
  ADD_RESERVATION_OK,
  REMOVE_RESERVATION_OK,
  ADD_RESERVATION,
  REMOVE_RESERVATION
} from "../actions/allActions";

export default function appRed(state = initialState, action) {
  switch (action.type) {
    case REMOVE_RESERVATION_OK:
      return { ...state, loading: false, error: null };
    case ADD_RESERVATION_OK:
      return { ...state, loading: false, error: null };
      case REMOVE_RESERVATION:
        return { ...state, loading: true, error: null };
      case ADD_RESERVATION:
        return { ...state, loading: true, error: null };
    case FETCH_DATA:
      return { ...state, loading: true };
    case LOGOFF:
      return { ...initialState };
    case RECEIVE_DATA:
      return {
        ...state,
        reservations: action.reservations,
        error: null,
        loading: false,
      };
    case IS_ERROR:
      return { ...state, error: action.error, loading: false };
    case IS_LOADING:
      return { ...state, loading: action.loading };
    case SEARCH:
      return { ...state, search: action.search };
    case FETCH_INFO:
      return { ...state, loading: true };
    case SET_USER:
      return { ...state, loading: false, error: null, user: action.user };
    case SHOW_MODAL:
      return {
        ...state,
        modal: action.modal,
        loading: false,
        info: action.info,
      };

    default:
      return state;
  }
}
