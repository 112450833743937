import ReactWeather from 'react-open-weather';
import 'react-open-weather/lib/css/ReactWeather.css';

const Weather = ()=>{
    return null;
    return(
        <div>
<ReactWeather
forecast="5days"
apikey="4ad1b1ead34ab27ca7c9ad327333f474"
type="city"
lang="de"
city="Lugano"/></div>
    );
}
export default Weather;