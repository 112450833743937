import Alert from "react-bootstrap/Alert";

const Error = (props) => {
  if (props.error) {
    return (
      <Alert variant="danger" onClose={() => props.isError(null)} dismissible>
        <Alert.Heading>Problem!</Alert.Heading>
        <p>{props.error}</p>
      </Alert>
    );
  }
};

export default Error;
