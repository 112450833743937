import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Spinner from "react-bootstrap/Spinner";
import { ParallaxBanner } from "react-scroll-parallax";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faUserCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
const Header = (props) => {
  const logout = (e) => {
    e.preventDefault();
    props.logout();
  };
 
 const imgSrc =  props.user !== null? "/img/house_logged_in.jpg":"/img/background_house.jpg";
  return (
    <div  className="mb-4">
      <Navbar expand="lg">
        <Navbar.Brand href="#">
          <FontAwesomeIcon icon={faHome} size="lg" /> Al Moessi &nbsp;&nbsp;&nbsp;
          {props.loading && (<Spinner animation="grow" size="sm" className="mb-1" variant="warning" />)}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        {props.user !== null && ( <Nav className="ml-auto mr-auto">
            <Nav.Link href="#buchung">Buchung</Nav.Link>
            <Nav.Link href="#stornierung">Stornierung</Nav.Link>
            <Nav.Link href="#information">Informationen</Nav.Link>
          </Nav>)}
          {props.user !== null && (
            <div className="mr-0">
              <Navbar.Text>
                <FontAwesomeIcon icon={faUserCircle} size="lg" /> Willkommen:{" "}
                <strong>{props.user.username}</strong> &nbsp;| &nbsp;&nbsp;
              </Navbar.Text>
              <Navbar.Text>
              Abmelden &nbsp;&nbsp;{" "}
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  size="lg"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    logout(e);
                  }}
                />
              </Navbar.Text>
            </div>
          )}
          
        </Navbar.Collapse>
      </Navbar>
      <ParallaxBanner
        className="paral"
        layers={[
          {
            image: imgSrc,
            amount: 0.5,
          },
        ]}
        style={{
          height: "400px",
        }}
      ></ParallaxBanner>
    </div>
  );
};
export default Header;
