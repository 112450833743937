import React, { useState } from "react";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import moment from "moment";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import * as locales from "react-date-range/dist/locale";
import mobile from 'is-mobile'
moment.locale("de-ch");

const Datepicker = (props) => {
  const initialState = {
    selection: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: props.user.userColor,
      guestHouse: 0
    },
  };
  const [state, setState] = useState(initialState);
  const [changed, setChanged] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);
  const reservations = props.reservations.map((item, i) => {
    let initDate = new Date(item.startDate);
let finalDate = new Date(item.endDate);
if(initDate.getTime() > finalDate.getTime()){
  initDate = new Date(item.endDate);
  finalDate = new Date(item.startDate);
}
    return {
      startDate: initDate,
      endDate: finalDate,
      key: item.username,
      color: item.userColor,
      userId: item.userId,
      user: item.username,
      guestHouse: item.guestHouse
    };
  });
  const handleChange = (e) =>{
    
    setCheckStatus(e.currentTarget.checked);
   // debugger;
  }
  const handleSubmit = (e) => {
    e.currentTarget.disabled=true;
    e.preventDefault();
    const st = checkStatus;
const chk = st===""||st==="0"||st===0||st===false?"0":"1";
let initDate = state.selection.startDate;
let finalDate = state.selection.endDate;
if(initDate.getTime() > finalDate.getTime()){
  initDate = state.selection.endDate;
  finalDate = state.selection.startDate;
}
    props.appActions.addReservation({
      startDate: initDate.toISOString(),
      endDate: finalDate.toISOString(),
      token: props.user.token,
      guestHouse: chk,
      userId: parseInt(props.user.id, 10),
    });
    setState(initialState);
    setChanged(false);
    setCheckStatus(false);
    e.currentTarget.disabled=false;
  };
  const disabled = (st) => {
    let all = [];
    Object.keys(st).map((k) => {
      // debugger;
      const interv = eachDayOfInterval({
        start: st[k].startDate,
        end: st[k].endDate,
      });
      all = all.concat(interv);
    });
    return all;
  };
  const pdL = mobile()? "40%" :"0";
  const lbl = (<div>Gästehaus &nbsp;&nbsp;<FontAwesomeIcon icon={faHome} size="lg" style={{color:"#007bff", opacity:"0.5"}} /></div>)
  const tc = mobile()?"text-center":"";
  return (
    <Row>
    <Col lg={12} className={tc}>
      <h4 id={"buchung"} className="mb-2">Buchung</h4>
      <DateRange style={{fontSize:"14px", fontWeight:"bold"}}
        locale={locales["de"]}
        disabledDates={disabled(reservations)}
        onChange={(item) => {
          setState({ ...state, ...item });
          setChanged(true);
        }}
        ranges={[state.selection]}
        minDate={addDays(new Date(), -1)}
      /></Col>
      {changed && (
        <Col lg={12}>
          <Alert variant={"primary"} style={{marginTop:"5rem"}}>
            <Row>
          <Col >
                deine Auswahl:
                <br />
                von:{" "}
                <strong>
                  {moment(state.selection.startDate).format("MMMM Do YYYY")}
                </strong>{" "}
                <br />
                zu:{" "}
                <strong>
                  {moment(state.selection.endDate).format("MMMM Do YYYY")}
                </strong>
              </Col>
              <Col  className="text-center">
                <Button
                  variant="outline-primary"
                  className="mt-3"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Reservieren
                </Button>
              </Col>
            </Row>
            <Row className="mt-2"><Col>
            <Form.Group  controlId="formBasicCheckbox">
            
    <Form.Check checked={checkStatus} onChange={(e)=>{handleChange(e)}} style={{paddingLeft:{pdL}}} type="switch" label={lbl} />
  </Form.Group>
            </Col></Row>
          </Alert>
          <br />
          <br />
        </Col>
      )}
    </Row>
  );
};

export default Datepicker;
