export const FETCH_DATA = "FETCH_DATA";
export const RECEIVE_DATA = "RECEIVE_DATA";
export const IS_ERROR = "IS_ERROR";
export const IS_LOADING = "IS_LOADING";
export const SEARCH = "SEARCH";
export const GET_INFO = "GET_INFO";
export const FETCH_INFO = "FETCH_INFO";
export const SHOW_MODAL = "SHOW_MODAL";
export const SET_USER = "SET_USER";
export const LOGOFF = "LOGOFF";

export const ADD_RESERVATION = "ADD_RESERVATION";
export const REMOVE_RESERVATION = "REMOVE_RESERVATION";
export const ADD_RESERVATION_OK = "REMOVE_RESERVATION_OK";
export const REMOVE_RESERVATION_OK = "REMOVE_RESERVATION_OK";
