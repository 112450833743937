import Navbar from "react-bootstrap/Navbar";
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Navbar className="justify-content-between">
      <Navbar.Text>
        <strong>&copy; Daniel Mössinger {year}</strong>
      </Navbar.Text>
      <Navbar.Text>
        {" "}
        <strong>Zur Buchung</strong>
      </Navbar.Text>
    </Navbar>
  );
};

export default Footer;
