import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import 'moment/locale/de-ch';
import mobile from 'is-mobile'
const mmt = moment;
mmt.locale("de-ch");
const TimeInfo = (props) => {
  if (!props.reservations || Object.keys(props.reservations).length <= 0) {
    return null;
  } else {
    const deleteItem = (e, item) => {
      e.preventDefault();

      confirmAlert({
        title: 'Delete booking',
        message: 'Do you want to delete this booking?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
                props.appActions.removeReservation({
                    reservation_id: item.reservation_id,
                    userId: props.user.id,
                    token: props.user.token,
                  });
            }
          },
          {
            label: 'No',
            onClick: () => console.log("NO delete")
          }
        ]
      });
      
    };
    const fDate = (dat) => {
      mmt.locale("de-ch");
      const changed = mmt(dat).format("MMMM Do YYYY")
      return changed;
    };
    const parsed = props.reservations.map((item, i) => {
      let initDate = new Date(item.startDate);
      let finalDate = new Date(item.endDate);
      if(initDate.getTime() > finalDate.getTime()){
        initDate = item.endDate;
        finalDate = item.startDate;
      } else{
        initDate = item.startDate;
        finalDate = item.endDate;
      }
      const borderBt = mobile()?'none':"dashed 1px grey";
      return (
        <Row
          key={i}
          className="mb-3"
          style={{ minHeight: "40px", color: item.userColor, borderBottom: {borderBt} }}
        >
          <Col lg={8}>
            {" "}
            <FontAwesomeIcon
              icon={faUserCircle}
              style={{ color: item.userColor }}
              size="lg"
            />{" "}
            <strong>{item.username}</strong>  {mobile() && <br />} {!mobile() && '\u00A0 \u00A0'} <i>from:</i>{'\u00A0'}{'\u00A0'}
            <strong>{fDate(initDate)}</strong> {mobile() && <br />}<i>To:</i>{'\u00A0'}{'\u00A0'}
            <strong>{fDate(finalDate)}</strong>{" "}
          </Col>
      <Col lg={3} xs={6}>Gästehaus: <strong>{item.guestHouse==="1"?"Ja":"Nein"}</strong></Col>
          <Col lg={1} className="mobRel">
            {props.user.id === item.userId && (
              <FontAwesomeIcon className="iconTrash"
                onClick={(e) => deleteItem(e, item)}
                icon={faTrashAlt}
                style={{ color: item.userColor, cursor: "pointer" }}
                size="lg"
              />
            )}{" "}
          </Col>{" "}
        </Row>
      );
    });

    return <div> <h4 className="mb-4" id={"stornierung"}>Stornierung</h4>{parsed}</div>;
  }
};

export default TimeInfo;
