import React from "react";
import Container from "react-bootstrap/Container";
import Header from "./components/Header/Header";
import { ParallaxProvider } from "react-scroll-parallax";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Datepicker from "./components/DatePicker/Datepicker";
import Information from "./components/Information/Information";
import Footer from "./components/Footer/Footer";
import Login from "./components/Login/Login";
import Error from "./components/Error/Error";
import TimeInfo from "./components/TimeInfo/TimeInfo";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "./actions/appActions";
import Weather from "./components/Weather/Weather";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }
  componentDidMount() {
    this.props.appActions.fetchData();
  }

  render() {
    const datesSet = (data) => {
      this.setState({ data: data });
    };
    return (
      <Container>
        <ParallaxProvider>
          <Header
            user={this.props.user}
            logout={this.props.appActions.logOff}
          />
        </ParallaxProvider><Weather/>
        {this.props.error && (
          <Row className="mt-4">
            <Col>
              <Error
                error={this.props.error}
                isError={this.props.appActions.isError}
              />
            </Col>
          </Row>
        )}
            {this.props.user !== null && (
              <Datepicker 
                {...this.props}
                setDate={(data) => {
                  datesSet(data);
                }}
                className="mt-4"
              />
            )}
    
         
        <Row className="mt-4">
          <Col className="timeInfoBlock">
            {" "}
            {this.props.user !== null && (
              <TimeInfo {...this.props} className="mt-4" />
            )}
          </Col>
        </Row>
        <Row>
          {this.props.user !== null && <Information />}
          {this.props.user === null && (
            <Col lg={12}>
              <Login {...this.props} />
            </Col>
          )}
        </Row>
        <Footer />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return state.appRed;
}

function mapDispatchToProps(dispatch) {
  return {
    appActions: bindActionCreators(appActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
