import {
  RECEIVE_DATA,
  FETCH_DATA,
  IS_ERROR,
  IS_LOADING,
  SHOW_MODAL,
  FETCH_INFO,
  SET_USER,
  LOGOFF,
  REMOVE_RESERVATION_OK,
  ADD_RESERVATION_OK,
  REMOVE_RESERVATION,
  ADD_RESERVATION,
} from "./allActions";

const backend = "https://booking.hoch.digital/back_api/";
//const backend = "http://localhost/back_api/"

export function receiveData(reservations) {
  return {
    type: RECEIVE_DATA,
    reservations,
  };
}

export function fetchingData() {
  return {
    type: FETCH_DATA,
    data: [],
  };
}

export function setError(error) {
  return {
    type: IS_ERROR,
    error,
  };
}

export function isError(error) {
  return (dispatch) => {
    setTimeout(() => dispatch(setError(null)), 5000);
    dispatch(setError(error));
  };
}
export function isLoading(loading) {
  return {
    type: IS_LOADING,
    loading,
  };
}
export function showModal(datos) {
  return {
    type: SHOW_MODAL,
    modal: datos.modal,
    info: datos.info,
  };
}
/*export function fetchInformation() {
  return {
    type: FETCH_INFO,
    modalInfo: [],
  };
}
export const fetchInfo = (id) => {
  return (dispatch) => {
    dispatch(isLoading(true));
    dispatch(fetchInformation());
    fetch("https://hn.algolia.com/api/v1/items/" + id, {
      timeout: 3000,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (info) {
        dispatch(
          showModal({
            info,
            modal: true,
          })
        );
      })
      .catch(function (error) {
        dispatch(isError(error.message));
      });
  };
};-*/
export const fetchData = (query = {}) => {
  return (dispatch) => {
    dispatch(fetchingData());
    fetch(backend + "reservations.php", {
      timeout: 3000,
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        dispatch(receiveData(data));
      })
      .catch(function (error) {
        dispatch(isError(error.message));
      });
  };
};

export const logOff = () => {
  return {
    type: LOGOFF,
  };
};
export const setUser = (data) => {
  return {
    type: SET_USER,
    user: data,
    error: null,
    loading:false
  };
};
export const doLogin = (username, password) => {
  return (dispatch) => {
    fetch(backend + "login.php", {
      method: "POST",
      body: JSON.stringify({ username, password }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          dispatch(isError(data.error));
        } else {
          dispatch(fetchData());
          dispatch(setUser(data));
        }
      })
      .catch((e) => {
        dispatch(isError(e.message));
      });
  };
};

export const addReservationStart = () => {
  return {
    type: ADD_RESERVATION,
    loading:true,
    error: null,
  };
};
export const removeReservationStart = () => {
  return {
    type: REMOVE_RESERVATION,
    loading:true,
    error: null,
  };
};
export const addReservationOK = () => {
  return {
    type: ADD_RESERVATION_OK,
    loading:false,
    error: null,
  };
};
export const removeReservationOK = () => {
  return {
    type: REMOVE_RESERVATION_OK,
    error: null,
    loading:false,
  };
};

export const addReservation = (reservation) => {
  return (dispatch) => {
     dispatch(addReservationStart());
    fetch(backend + "add.php", {
      method: "PUT",
      timeout: 3000,
      headers: new Headers({ Authorization: "Bearer " + reservation.token }),
      body: JSON.stringify({
        startDate: reservation.startDate,
        endDate: reservation.endDate,
        userId: reservation.userId,
        guestHouse: reservation.guestHouse || false,
      }),
    })
      .then(function (response) {
        return response.status;
      })
      .then(function (data) {
        dispatch(addReservationOK());
        dispatch(fetchData());
      })
      .catch(function (error) {
        dispatch(isError(error.message));
      });
  };
};
export const removeReservation = (reservation) => {
  return (dispatch) => {
    dispatch(removeReservationStart());
    fetch(backend + "delete.php", {
      method: "DELETE",
      timeout: 3000,
      headers: new Headers({ Authorization: "Bearer " + reservation.token }),
      body: JSON.stringify({
        reservation_id: reservation.reservation_id,
        userId: reservation.userId,
      }),
    })
      .then(function (response) {
        return response.status;
      })
      .then(function (data) {
        dispatch(removeReservationOK());
        dispatch(fetchData());
      })
      .catch(function (error) {
        dispatch(isError(error.message));
      });
  };
};
